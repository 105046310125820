import { useState, useEffect, useCallback } from 'react';
import { Connection } from '@solana/web3.js';
//  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export const useConnection = () => {
    //  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    const endpoint = 'https://morning-magical-flower.solana-mainnet.quiknode.pro/b8ebb90266da774d50aa20a93a2108f9a5882032/';
    //  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    const [connection, setConnection] = useState<Connection | null>(null);
    const [isConnected, setIsConnected] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    //  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    const fetchConnection = useCallback(async () => {
        try{
            if (!endpoint) {return;}
            try {
                const connection = new Connection(endpoint, 'confirmed');
                setConnection(connection);
                await connection.getVersion();
                setIsConnected(true);
            } catch (error) {
                setError(error as Error);
                setIsConnected(false);
            }
        } catch(e){}
    }, [endpoint]);
    //  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    useEffect(() => {fetchConnection();}, [fetchConnection]);
    //  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    return { endpoint, connection, isConnected, error };
};
