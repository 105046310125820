export const dbConfig = async () => {
  return {
    apiKey: "AIzaSyCuYAUei5KLKS3KAEdvODRuaAiPYLWIY5g",
    authDomain: "bemeowdapp.firebaseapp.com",
    projectId: "bemeowdapp",
    storageBucket: "bemeowdapp.appspot.com",
    messagingSenderId: "1036049509853",
    appId: "1:1036049509853:web:1248842b50c91b67079a34",
    measurementId: "G-4DRVM2MN35"
  };
};
