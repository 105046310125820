import ErrorBoundary from '../ErrorBoundary'; 
import React, { useState } from 'react';
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
interface FAQWindowProps {
  onClose: () => void;
}
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
const FAQWindow: React.FC<FAQWindowProps> = ({ onClose }) => {

  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const faqData = [
    { question: "What is BeMeow?", 
        answer: "BeMeow Records (Beats of Meow) is the very first memeable Web3 Music Label in the World." },

    { question: "What problem does BeMeow solve?", 
        answer: "For over 20 years (since MP3 files were invented), Artist have been paid only worse. With BeMeow, Artists can finally make a living from their music again." },
    
    { question: "How does BeMeow solve this problem?", 
        answer: "BeMeow launches a Memecoin for every song release. From the trading BeMeow pays Artists 1% of the trading trading volume." },

    { question: "How do you link music with a token?", 
        answer: "We upload the full song and metadata on the Solana and Arweave blockchain, plus the metadata as ordinal inscription on the Bitcoin blockchain. Links and pointers to these data will be stored inside the mint account of the tradeable token on Solana." },
    
    { question: "What is an ICO?", 
        answer: "An Initial Coin Offering (ICO) is a type of fund raising using cryptocurrencies. Investors can buy at lower prices before the coin is even tradeable on the open market." },
    
    { question: "How can I buy $BEME?", 
        answer: "You need a cryptocurrency wallet supporting the Solana blockchain, such as Phantom or Ledger. You also need one of the crypto assets in the list, such as Solana or USDC." },
    
    { question: "How can I get a crypto asset to buy $BEME?", 
        answer: "On a centralized exchange (such as Binance or Coinbase), you can buy them using fiat currency, e.g. USD, EUR or other legal tenders." },
    
    { question: "How can I change buy using a crypto asset from the list?", 
        answer: "You can send the crypto asset from the exchange to your wallet. Then you can connect your wallet here and buy $BEME with your desired amount." },
    
    { question: "Why can't I get all my coins right now when I buy?", 
        answer: "Everyone who has the coin can open a liquidity pool. The ratio of $BEME and the other asset in the pool decide the starting price. We make sure, this price is higher than what you pay in this ICO." },
    
    { question: "Why is a vesting required?", 
        answer: "A vesting reduces sell pressure to promote the price of $BEME rises and the chart stays healthy." },
    
    { question: "How does vesting work?", 
        answer: "Releasing chunks of your coin allocation in intervals. Once a month you receive 5% of your $BEME." },
    
    { question: "What is Web3?", 
        answer: "Web3 is the Internet-of-Value. Assets and digital currencies can be transferred and programmed via Blockchain." },
    
    { question: "What is Blockchain?", 
        answer: "Blockchain is a decentralized ledger technology that securely records transactions across many computers." },
    
    { question: "Are there risks in an ICO?", 
        answer: "Investing is always a risk and doing your own research is an important responsibility. However, we are extremely dedicated and work hard to make this a project a game changing success." },
    
    { question: "What else does BeMeow plan?", 
        answer: "We want to build an ecosystem, involving liquidity farming, community rewards, buybacks, club events, festivals, a music play-to-earn game, song and artist NFTs, copyright tokens, music portfolio, and many more." },
    
    { question: "Which music genres does BeMeow support?", 
        answer: "We are open minded to all types of quality music. However, we especially seek music with motivating lyrics and energetic beats or funny music with meme-factor." },  
    
    { question: "More questions?", 
        answer: "Join our Telegram: https://t.me/bemeow or contact support@bemeow.club" },  

];


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
  const toggleAnswer = (index: number) => {
    try{
      setOpenIndex(openIndex === index ? null : index);
  } catch(e){}
  };
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
  try{
      return (
        <ErrorBoundary>
          <div className="faq-wrapper">
            <div className="faq-close-button" onClick={onClose}>
              <span className="faq-close-button-span">X</span>
            </div>
            <div className="faq-header">
              <h2 className="faq-title">FAQ</h2>
                  <hr></hr>
              <div className="faq-content">
                {faqData.map((item, index) => (
                  <div key={index}>
                    <div className="faq-toggle-and-question-div" onClick={() => toggleAnswer(index)}>
                      <span className="faq-question-toggle">{openIndex === index ? '-' : '+'} </span>
                      <span className="faq-question">{item.question}</span>
                    </div>
                    {openIndex === index && (
                      <div>
                        <span className="faq-answer">{item.answer}</span>
                      </div>
                    )}
                    <hr></hr>
                    
                  </div>
                ))}
              </div>
            </div>
          </div>
        </ErrorBoundary>
      );
  } catch(e){}
};
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
export default FAQWindow;
