import React, { useState, useRef, useEffect } from 'react';

const MusicPlayer = () => {
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const songs = [
    { name: "MoonsOfJupiter", file: "/songs/MoonsOfJupiter.mp3" },
    { name: "DubaiInvestors", file: "/songs/DubaiInvestors.mp3" },
    { name: "Soundclash", file: "/songs/Soundclash.mp3" },
    { name: "Stronger", file: "/songs/Stronger.mp3" },
    { name: "WorkForYourBags", file: "/songs/WorkForYourBags.mp3" },
    { name: "BemeMeUp", file: "/songs/BemeMeUp.mp3" },
  ];

  const handleSongEnd = () => {
    setCurrentSongIndex((prevIndex) => (prevIndex + 1) % songs.length);
  };

  const playAudio = async () => {
    try {
      await audioRef.current.play();
      setIsPlaying(true);
      console.log('Audio started successfully.');
    } catch (error) {
      console.log('Audio play was blocked. Waiting for user interaction...');
    }
  };

  useEffect(() => {
    playAudio();
  }, [currentSongIndex]);

  const enableAudioOnInteraction = () => {
    playAudio();
    window.removeEventListener('click', enableAudioOnInteraction);
    window.removeEventListener('scroll', enableAudioOnInteraction);
  };

  useEffect(() => {
    window.addEventListener('click', enableAudioOnInteraction);
    window.addEventListener('scroll', enableAudioOnInteraction);

    return () => {
      window.removeEventListener('click', enableAudioOnInteraction);
      window.removeEventListener('scroll', enableAudioOnInteraction);
    };
  }, []);

  return (
    <div className="music-player">
      <audio
        ref={audioRef}
        src={songs[currentSongIndex].file}
        onEnded={handleSongEnd}
        autoPlay
      />
    </div>
  );
};

export default MusicPlayer;
